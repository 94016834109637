@charset "UTF-8";

@font-face {
    font-family: "fonts";
    src        : url("../fonts/nask/fonts.eot");
    src        : url("../fonts/nask/fonts.eot?#iefix") format("embedded-opentype"),
        url("../fonts/nask/fonts.woff") format("woff"),
        url("../fonts/nask/fonts.ttf") format("truetype"),
        url("../fonts/nask/fonts.svg#fonts") format("svg");
    font-weight: normal;
    font-style : normal;

}

[data-icon]:before {
    font-family            : "fonts" !important;
    content                : attr(data-icon);
    font-style             : normal !important;
    font-weight            : normal !important;
    font-variant           : normal !important;
    text-transform         : none !important;
    speak                  : none;
    line-height            : 1;
    -webkit-font-smoothing : antialiased;
    -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
    font-family            : "fonts" !important;
    font-style             : normal !important;
    font-weight            : normal !important;
    font-variant           : normal !important;
    text-transform         : none !important;
    speak                  : none;
    line-height            : 1;
    -webkit-font-smoothing : antialiased;
    -moz-osx-font-smoothing: grayscale;    
    font-size: 32px;
}

.icon-communication:before {
    content: "\61";
}

.icon-daily:before {
    content: "\62";
}

.icon-project:before {
    content: "\63";
}

.icon-timezone:before {
    content: "\64";
}

.icon-trackingtools:before {
    content: "\65";
}

.icon-ui-detail:before {
    content: "\67";
}

.icon-ui-experience:before {
    content: "\68";
}

.icon-ui-inno:before {
    content: "\69";
}

.icon-ui-std:before {
    content: "\6a";
}

.icon-ui-timely:before {
    content: "\6b";
}

.icon-ux-document:before {
    content: "\6c";
}

.icon-ux-inno:before {
    content: "\6d";
}

.icon-ux-minds:before {
    content: "\6e";
}

.icon-ux-user:before {
    content: "\6f";
}